import api from '../index'

const question = {
    // 获取项目问题
    questionInfo(data) {
        return api({
            url: 'v2/ai/item-question',
            method: 'post',
            data: data
        })
    },
    // 回答项目问题
    addQuestion(data) {
        return api({
            url: 'v2/ai/answer',
            method: 'post',
            data: data
        })
    }
}
export default question
