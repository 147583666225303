import api from '../index'

const item = {
    // 项目详情
    itemInfo(data) {
        return api({
            url: 'v2/ai/item-info',
            method: 'post',
            data: data
        })
    },
    // 项目列表
    itemList(data) {
        return api({
            url: 'v2/ai/items-list',
            method: 'post',
            data: data
        })
    },
    // 项目添加
    itemAdd(data) {
        return api({
            url: 'v2/ai/items-add',
            method: 'post',
            data: data
        })
    }
}
export default item
