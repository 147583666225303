<!-- 主页 -->
<template>
  <div class="page-main"  id="message">
    <div v-if="loading" class="login-loading">
      <van-loading type="spinner" vertical>
        加载中...
      </van-loading>
    </div>
    <div v-else class="chat-card">
      <!-- 主页 -->
      <div class="chat-title">
        <div class="chat-name">
          <van-icon name="arrow-left" size="20" color="#333" @click="goBack" />
          <div class="chat-name-desc">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="chat-list">
        <div class="message-module" v-for="item in messageList" :key="item.ques_id">
          <div v-if="item.type==1">
            <div v-if="item.ques_type==1" class="message-item">
              <div>
                <img src="@/assets/image/avator.png" class="avator-img" >
              </div>
              <div class="message-desc item-left">
                {{item.content}}
                <div class="add-base" v-if="item.remarks != null && item.remarks.length > 0">
                  {{item.remarks}}
                </div>
              </div>
            </div>
            <div v-else-if="item.ques_type==2">
              <div class="message-item">
                <div>
                  <img src="@/assets/image/avator.png" class="avator-img" >
                </div>
                <div class="message-desc item-left">
                  {{item.content}}
                  <div class="add-base" v-if="item.remarks != null && item.remarks.length > 0">
                    {{item.remarks}}
                  </div>
                </div>
              </div>
              <div class="message-item">
                <div class="chose-card">
                  <div class="item-attention">请选择</div>
                  <div class="attention-line" />
                  <div class="chose-list">
                    <div v-for="iItem in item.extend" :key="iItem.id" class="chose-item" @click="checkAdd(iItem)">
                      {{ iItem.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="message-item-right">
            <div v-if="item.loading != null && item.loading">
              <van-loading size="24" type="spinner" />
            </div>
            <div class="message-desc item-right">
              {{item.answer}}
            </div>
          </div>
        </div>
        <div class="message-module"> 
          <div v-if="lastLoading" class="last-message-item">
            <div>
              <img src="@/assets/image/avator.png" class="last-avator-img" >
            </div>
            <div class="last-message-desc last-item-left">
              {{lastMessage.content}}
              <div class="last-add-base" v-if="lastMessage.remarks != null && lastMessage.remarks.length > 0">
                {{lastMessage.remarks}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new-card">
        <div class="new-item" @click="goHome">
          <div class="item-attention">
            <van-icon name="plus" color="#4BBE79" size="14px" />
            <div>
              创建新筛查
            </div>
          </div>
        </div>
      </div>
      <div class="chat-footer">
        <div class="message-card">
          <div class="message-input">
            <van-field 
              v-model="message"
              placeholder="请输入待发送消息"
              rows="1"
              autosize
              :readonly="isFinish || lastLoading || sendLoading || ques_type == 2"
              type="textarea"
              class="my-input"
            />
          </div>
          <div class="opt-group">
            <van-button :disabled="isFinish || lastLoading || sendLoading || ques_type == 2" round class="send-btn" icon="passed" @click="sendMessage"></van-button>
            <!-- <van-icon name="guide-o" color="#000" size="40" @click="sendMessage" /> -->
          </div>
        </div>
        <!-- <div class="footer-line" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { setSession } from "../../utils/sessionUtils"
import CommonData from "../../utils/commonData"
import { Toast } from 'vant'
import item from "@/api/item/item"
import question from "@/api/item/question"

export default {
  name: "home",
  components: {
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      console.log('home=====>')
      //获取传入参数
      data.loading = false
      const options = $router.currentRoute.value.query;
      console.log(options)
      // 检测是否结束
      if (options) {
        if (options.id && options.launch_id) {
          data.id = options.id
          data.launch_id = options.launch_id
          getTempInfo()
        }
      }
    });
    const getTempInfo = () => {
      const post_data = {
        id: data.launch_id
      }
      item.itemInfo(post_data).then(res=>{
        data.title = res.data.temp_name
        // if (res.data.is_finish == 1) {
        //   data.isFinish = res.data.is_finish
        //   data.title += "(已结束)"
        // } else {
        //   getQuestion()
        // }
        getQuestion()
      })
    }
    const getQuestion = () => {
      const post_data = {
        id: data.id,
        launch_id: data.launch_id,
        sort_num: data.sort_num
      }
      question.questionInfo(post_data).then(res=>{
        if (data.sort_num == null) {
          console.log(res.data)
          data.messageList = []
        }
        setAnswer(res.data)
        data.sort_num = res.data.sort_num
        data.loading = false
      })
    }
    const goBind = () => {
      $router.push({
        name: 'bind'
      })
    }
    const goBack = () => {
      $router.go(-1)
    }
    const goHome = () => {
      $router.push({
        name: 'home'
      })
    }
    const sendMessage = () => {
      if (data.message == null || data.message.length == 0) {
        Toast('发送消息不能为空！');
        return
      }
      const post_data = {
        id: data.id,
        launch_id: data.launch_id,
        sort_num: data.sort_num,
        answer: data.message
      }
      const sendMessage = {
        id: -1,
        type: 2,
        launch_id: data.launch_id,
        sort_num: data.sort_num,
        answer: data.message,
        loading: true
      }
      data.messageList.push(sendMessage)
      data.sendLoading = true
      data.message = ''
      question.addQuestion(post_data).then(res => {
        console.log(res)
        data.sort_num = res.data.question.sort_num
        // 获取内容
        var findSend = data.messageList.find(o => o.id == -1)
        findSend.id = res.data.answer.id
        findSend.loading = false
        if (res.data.question.sort_num == -1) {
          data.isFinish = true
        }
        setAnswer(res.data.question)
      })
    }
    const checkAdd = item => {
      console.log(data.sort_num)
      if (data.sendLoading || data.isFinish || (item.next_sort_num != -1 && data.sort_num >= item.next_sort_num)) {
        return
      }
      console.log(item)
      const post_data = {
        id: data.id,
        launch_id: data.launch_id,
        sort_num: data.sort_num,
        next_sort_num: item.next_sort_num,
        answer: item.id
      }
      question.addQuestion(post_data).then(res => {
        console.log(res)
        data.sort_num = res.data.question.sort_num
        // 获取内容
        if (res.data.question.sort_num == -1) {
          data.isFinish = true
        }
        setAnswer(res.data.question)
      })
    }
    const setAnswer = message => {
      data.lastMessage = null
      data.lastLoading = true
      console.log(message)
      data.ques_type = message.ques_type
      var sendIndex = 0
      var content = ""
      var remarksIndex = 0
      var remarks =""
      if (message.extend!= null && message.extend.length > 0) {
        var jsonExtend = JSON.parse(message.extend)
        message.extend = jsonExtend
      } 
      var intervalId = setInterval(() => {
        if (data.lastMessage == null || data.lastMessage.content == null || data.lastMessage.content.length < message.content.length)  {
          content += message.content.substring(sendIndex, sendIndex + 1)
          var lastMessage = {
            id: message.id,
            content: content,
            type: message.type
          }
          sendIndex+=1
          data.lastMessage = lastMessage
        } else if (message.remarks != null && message.remarks.length > 0 && (data.lastMessage.remarks == null || data.lastMessage.remarks.length < message.remarks.length)) {
          remarks += message.remarks.substring(remarksIndex, remarksIndex + 1)
          var lastMessage = {
            id: message.id,
            content: message.content,
            type: message.type,
            remarks: remarks
          }
          remarksIndex+=1
          data.lastMessage = lastMessage
        } else {
          data.lastLoading = false
          data.sendLoading = false
          data.messageList.push(message)
          clearInterval(intervalId)
        }
        setTimeout(() => {
          scrollBottom()
        }, 100)
      }, 100)
    }
    const scrollBottom = () => {
      var element = document.getElementById("message");
      element.scrollTop = element.scrollHeight;
    }
    const data = reactive({
      id: null,
      launch_id: null,
      sort_num: null,
      loading: true,
      isFinish: false,
      messageList: [],
      message: '',
      send_id: 3,
      title: '',
      lastLoading: false,
      sendLoading: false,
      lastMessage: {},
      ques_type: 0
    });

    return {
      ...toRefs(data),
      goBind,
      goBack,
      sendMessage,
      goHome,
      checkAdd
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #EEE;
  .login-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // .chat-card {
  //   position: relative;
  //   width: 100%;
  //   height: 100%;
  // }
  .chat-title {
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    background: linear-gradient(to right,#E6F8FA, #DEFAEE);
    height: 40px;
    .chat-name {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 20px;
      .chat-name-desc {
        width: calc(100% - 40px);
        text-align: center;
        font-size: 18px;
        line-height: 40px;
        color: #333;
        max-width: calc(100% - 40px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .login-attention {
    width: 100%;
    padding: 50px;
    .bind-btn {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .chat-list {
    width: 100%;
    padding: 10px;
    .last-message-item {
      font-size: 12px;
      line-height: 20px;
      color: #000;
      display: flex;
      .last-avator-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      .last-message-desc {
        background: #fff;
        padding: 10px;
      }
      .last-item-left {
        margin-left: 10px;
        position: relative;
        background: #fff;
        border-radius: 5px 20px 20px 20px;
        .last-add-base {
          border: 1px dashed #00DDDD;
          margin-top: 5px;
          display: block;
          font-size: 10px;
          background: rgb(187, 255, 238, 0.2);
          padding: 5px;
          border-radius: 5px;
          color: #00DDDD;
        }
      }
      // .last-item-left {
      //   margin-left: 10px;
      //   position: relative;
      //   background: #fff;
      //   border-radius: 5px;
      //   .last-add-base {
      //     border: 1px dashed #00DDDD;
      //     margin-top: 5px;
      //     display: block;
      //     font-size: 10px;
      //     background: rgb(187, 255, 238, 0.2);
      //     padding: 5px;
      //     border-radius: 5px;
      //     color: #00DDDD;
      //   }
      // }
      // .last-item-left:after {
      //   content: '';
      //   width: 0;
      //   height: 0;
      //   position: absolute;
      //   top: 5px;
      //   left: -14px;
      //   border: solid 8px;
      //   border-color: transparent #fff transparent transparent ;
      //   font-size: 0;
      // }
    }
    .message-module {
      margin-top: 10px;
      flex-wrap: nowrap;
      &:first-child {
        margin-top: 40px;
      }
      &:last-child {
        margin-bottom: 180px;
      }
      .message-item {
        font-size: 12px;
        line-height: 20px;
        color: #000;
        display: flex;
        .avator-img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        .message-desc {
          background: #fff;
          padding: 10px;
        }
        .item-left {
          margin-left: 10px;
          position: relative;
          background: #fff;
          border-radius: 5px 20px 20px 20px;
          .add-base {
            border: 1px dashed #00DDDD;
            margin-top: 5px;
            display: block;
            font-size: 10px;
            background: rgb(187, 255, 238, 0.2);
            padding: 5px;
            border-radius: 5px;
            color: #00DDDD;
          }
        }
        // .item-left {
        //   margin-left: 10px;
        //   position: relative;
        //   background: #fff;
        //   border-radius: 5px;
        //   .add-base {
        //     border: 1px dashed #00DDDD;
        //     margin-top: 5px;
        //     display: block;
        //     font-size: 10px;
        //     background: rgb(187, 255, 238, 0.2);
        //     padding: 5px;
        //     border-radius: 5px;
        //     color: #00DDDD;
        //   }
        // }

        // .item-left:after {
        //   content: '';
        //   width: 0;
        //   height: 0;
        //   position: absolute;
        //   top: 5px;
        //   left: -14px;
        //   border: solid 8px;
        //   border-color: transparent #fff transparent transparent ;
        //   font-size: 0;
        // }
        .chose-card {
          width: 100%;
          background: #fff;
          margin: 10px;
          padding: 20px;
          border-radius: 10px;
          .item-attention {
            font-size: 16px;
            font-weight: bold;
          }
          .attention-line {
            margin: 10px 0;
            border-top: thin solid #53BC7D;
          }
          .chose-list {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .chose-item {
              margin: 5px;
              padding: 10px;
              background: #ECF9F1;
              color: #4BBE79;
              border-radius: 5px;
              min-width: 50px;
              text-align: center;
            }
          }
        }
      }
      .message-item-right {
        font-size: 12px;
        line-height: 20px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: right;
        .message-desc {
          background: #fff;
          padding: 10px;
        }
        .item-right {
          text-align: right;
          margin-left: 10px;
          position: relative;
          background: #69C97C;
          border-radius: 20px 5px 20px 20px;
        }
        // .item-right {
        //   text-align: right;
        //   margin-left: 10px;
        //   position: relative;
        //   background: #69C97C;
        //   border-radius: 5px;
        // }
        // .item-right:after {
        //   content: '';
        //   width: 0;
        //   height: 0;
        //   position: absolute;
        //   top: 5px;
        //   right: -14px;
        //   border: solid 8px;
        //   border-color: transparent transparent transparent #69C97C;
        //   font-size: 0;
        // }
      }
    }
  }
  .new-card {
    width: 100%;
    background: transparent;
    height: 40px;
    position: absolute;
    bottom: 100px;
    .new-item {
      display: inline-block;
      margin: 5px;
      padding: 10px;
      background: #ECF9F1;
      color: #4BBE79;
      border-radius: 20px;
      .item-attention {
        display: flex;
        align-items: center;
      }
    }
  }
  .chat-footer {
    width: 100%;
    background: #EEE;
    height: 80px;
    position: absolute;
    bottom: 0;
    .message-card {
      margin: 0 20px;
      border-radius: 20px;
      box-shadow: 0 2px 12px 0 #bbb;
      background: #EEE;
      padding: 0 10px;
      max-height: 60px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      .message-input {
        width: calc(100% - 75px);
        .my-input {
          border: 0px;
          max-height: 40px;
          border-radius: 20px;
          background: #eee;
          font-size: 12px;
          line-height: 18px;
          padding: 5px 12px;
        }
      }
      .opt-group {
        .send-btn {
          background: transparent;
          height: 30px;
        }
      }
      .message-finish {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        color: #999;
        text-align: center;
      }
    }
  }
}

</style>
